<template>
    <div class="container">
        <div class="container content">
            <div class="title-box">
                <h1>Resources</h1>
                <div class="title-container center">
                  <!--<div class="content-panel box-third training-panel">
                    <a href="#" target="_blank" class="no-underline">
                        <div class="panel-title">GEPI.io Training Guide</div>
                        <div class="panel-line center">
                          <div class="panel-content">Learn how to access all GEPI.io has to offer</div>
                        </div>
                    </a>
                </div>-->
                <div class="content-panel box-third training-panel">
                  <a href="https://form.asana.com/?k=FbRWF_piVAuPfJEQzJuAIw&d=1198720002001006" target="_blank" class="no-underline">
                      <div class="panel-title">Support & Ideas Form</div>
                      <div class="panel-line center">
                        <div class="panel-content">Provide feedback to the Tech Team</div>
                      </div>
                  </a>
              </div>
              <!--<div class="content-panel box-third training-panel">
                    <a href="#" target="_blank" class="no-underline">
                        <div class="panel-title">Guide Title</div>
                        <div class="panel-line center">
                          <div class="panel-content">Training/guide description</div>
                        </div>
                    </a>
                </div>-->
              </div>
            </div>
            <div class="title-box2">
                <h1>Notifications</h1>
            </div>
            <div class="releases">
              <!--<div class="content-box update-box">
                    <div class="panel-title release-date">September 2023: Historical Data</div>
                    <div class="box-container">
                      <div class="release-notes">
                        <p>A brief summary here about the new data that can be found in GEPI.io - project summary/outcomes/etc. Funder/partner descriptions. Addition of file management system.</p>
                        <ul class="feature-list">
                          <li><strong>Feature 1 short description:</strong> Additional information about the feature, similar to how Asana does it. It should be clear but succinct.</li>
                          <li><strong>Feature 2 short description:</strong> Additional information about the feature, similar to how Asana does it. It should be clear but succinct.</li>
                          <li><strong>Feature 3 short description:</strong> Additional information about the feature, similar to how Asana does it. It should be clear but succinct.</li>
                        </ul>
                        <p>We look forward to collaborating with you to continue to build new features and functionalities that will best support your work. We have lots of ideas up our sleeves and plans for expansion of the application, but we'd love to hear from you! How can we improve our application? What features would be most beneficial to you? Submit the <a href="https://form.asana.com/?k=FbRWF_piVAuPfJEQzJuAIw&d=1198720002001006" target="_blank">GEPI Support and Ideas form</a> to let us know!</p>
                      </div>
                    </div>
                </div>-->
                <div class="content-box update-box">
                    <div class="panel-title release-date">January 25, 2023: Welcome to GEPI.io</div>
                    <div class="box-container">
                      <div class="release-notes">
                        <p>We are excited to unveil the beta version of GEPI.io, the new internal program management software built for the Centers. The Tech Team has been purposeful in our effort to build a tool to support you - our staff - with a brand new, user-friendly application. Our initial launch includes a new user interface design and the deployment of our core data structure, including the rollout of the following new features: </p>
                        <ul>
                          <li><a href="/grants/"><strong>Grants</strong></a></li>
                          <li><a href="/programs/"><strong>Programs</strong></a></li>
                          <li><a href="/projects/"><strong>Projects</strong></a></li>
                          <li><a href="/contacts/"><strong>Contacts</strong></a>
                            <ul>
                              <li><a href="/contacts/organizations/"><strong>Organizations</strong></a></li>
                              <li><a href="/contacts/people/"><strong>People</strong></a></li>
                            </ul>
                          </li>
                        </ul>
                        <p><strong>Scavenger Hunt</strong>! Try to use GEPI.io to answer the following questions.
                          <ul>
                            <li>How many contacts do we have in Columbia, SC?</li>
                            <li>Who is the Director of Community and Family Resources for Bloomington, IN?</li>
                            <li>What grant does the GEAR Challenge program belong to?</li>
                            <li>Who is the funder of the EAGER grant?</li>
                          </ul>
                        </p>
                        <p>We look forward to collaborating with you to continue to build new features and functionalities that will best support your work. We have lots of ideas up our sleeves and plans for expansion of the application, but we'd love to hear from you! How can we improve our application? What features would be most beneficial to you? Submit the <a href="https://form.asana.com/?k=FbRWF_piVAuPfJEQzJuAIw&d=1198720002001006" target="_blank">GEPI Support and Ideas form</a> to let us know!</p>
                      </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style>
/* Page CSS */
.title-box {
    margin: 0px -50px 0px -50px;
    background: url("../assets/images/login-bg.svg") no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}
h1 {
  font-size: 1.5em;
  padding-top: 20px;
}
.title-box h1 {
    color: white;
    padding: 10px 50px;
}
.title-container {
  padding: 0px 50px;
}
.training-panel {
  margin: 15px;
}
.training-panel .panel-title {
    font-family: 'roboto-bold';
}
.releases .release-date {
  font-family: 'roboto-bold';
}

.releases .release-date {
  background: var(--very-dark-grey);
  color: var(--white);
  text-align: initial;
  font-family: 'roboto-bold';
  padding: 10px;
  font-size: 20px;
}
.releases .release-notes {
  padding: 10px;
  line-height: 24px;
}
.release-notes p {
    margin: 5px 0px 15px 0px;
}
.content-box.update-box {
  padding: 0px;
  margin: 10px 30px 40px 0px;
  border-radius: 3px;
}
.release-notes h2 {
  font-size: 18px;
}
.feature-list li {
    margin: 10px 0px;
}
</style>

<script>
    import axios from 'axios';

    export default {
        title () {
          return `GEPI: Notifications`
        },
        components: {
        },
        data() {
        },
        created() {
        },
        computed: {
        },
        methods: {
        }
    }
    </script>